import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

export const LandingPageColumn = ({
  title,
  description,
  linkText,
  linkTo,
  id,
  theme,
  active,
  columnId,
  activateCol,
  item,
  long_description,
}) => (
  <LandingPageColumnStyle
    theme={theme}
    id={id}
    active={active}
    columnId={columnId}
  >
    <Container onMouseEnter={() => activateCol(id, item)}>
      <ColumnContent name={title} className="text_container" active={active}>
        <h2>{title}</h2>
        <p className="short_description">{description}</p>
        <p className="long_description">{active ? long_description : null}</p>

        <Link to={`/${linkTo}`}>{linkText}</Link>
      </ColumnContent>
    </Container>
  </LandingPageColumnStyle>
);

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const LandingPageColumnStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 1;
  background-color: ${(p) => (p.active ? '#F5F4ED' : null)};
  opacity: ${(p) => (p.active || p.columnId === undefined ? 1 : 0)};
  transition: 450ms;
`;

const ColumnContent = styled.div`
  max-width: ${(p) => (p.active ? 250 : 200)}px;
  margin: 0 20px;
  transition: 450ms;
  text-align: center;
  transform: scaleY(1);
  transform-origin: top;
  transition: transform 0.25s ease;
  h2 {
    font-size: 3rem;
    color: ${(p) => (p.active ? '#000' : '#fff')};
    font-size: ${(p) => (p.active ? 4 : 3)}rem;
    transition: 450ms;
  }

  p {
    color: ${(p) => (p.active ? '#000' : '#fff')};
    margin: 20px 0px;
    line-height: 1.7rem;
    font-size: clamp(12px, 2vw, 1.5rem);
  }

  .long_description {
    @media screen and (max-width: 868px) {
      display: none;
    }
  }

  a {
    color: ${(p) => (p.active ? '#000' : '#fff')};
    font-size: ${(p) => (p.active ? 1.6 : 1.4)}rem;
    transition: 450ms ease-in;
    text-decoration: none;
    /* border-bottom: 1px solid ${(p) => (p.active ? '#000' : '#fff')}; */
    padding: 3px;
    text-transform: uppercase;
    list-style: none;
  }
`;
