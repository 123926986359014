import React, { useContext, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { LandingPageColumn } from '../components/LandingPageColumn';
import { Logo } from '../components/Logo';
import StoreContext from '../components/Context';
import { FullScreenVideo } from '../components/FullScreenVideo';
import { ContactText } from '../components/ContactText';
import { SEO } from '../components/SEO';

export default function HomePage({ data }) {
  const [store, setStore] = useContext(StoreContext);
  const [screenWidth, setScreenWidth] = useState(undefined);
  const { allSanityHomecols, allSanityStoreSettings } = data;
  const videoUrl = allSanityStoreSettings?.edges[0]?.node.front_video.asset.url;
  const contact_text = allSanityStoreSettings?.edges[0]?.node.contact_text;
  const activateCol = (id, item) => {
    setStore({
      ...store,
      columnId: id,
      colData: item,
    });
  };

  useEffect(() => {
    if (window.innerWidth) {
      setScreenWidth(window.innerWidth);
    }
  }, []);

  const { columnOrder, columnId } = store;

  const renderVideo = () => {
    if (screenWidth < 800) {
      return videoUrl;
    }
    // Disable background movie to change on mouseover. //Filtred RWTV in sanity query.
    // if (store.colData?.node?.video.asset.url) {
    //   return store.colData?.node?.video.asset.url;
    // }
    return videoUrl;
  };

  return (
    <>
      <SEO title="Rumblewood: We make Story-driven films & campaigns." />
      <Logo color={columnId === 0 ? 'black' : 'white'} />
      <div>
        <FullScreenVideo
          active={columnId === columnOrder}
          videoUrl={renderVideo()}
        />
        <LandingPageColumnContainer>
          {allSanityHomecols.edges.map((item, i) => (
            <LandingPageColumnHolder key={i}>
              <LandingPageColumn
                activateCol={activateCol}
                item={item}
                id={i}
                theme="white"
                columnId={columnId}
                active={columnId === i}
                long_description={item.node.long_description}
                video={item.node.video.asset.url}
                title={item.node.title}
                description={item.node.description}
                linkText={item.node.link}
                linkTo={item.node.linkTo}
              />
            </LandingPageColumnHolder>
          ))}
        </LandingPageColumnContainer>
        <ContactText
          text={contact_text}
          columnId={columnId}
          active={columnId === columnOrder}
        />
      </div>
    </>
  );
}

const LandingPageColumnHolder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const LandingPageColumnContainer = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 868px) {
    flex-direction: column;
  }
`;

export const query = graphql`
  {
    allSanityHomecols(
      filter: { title: { nin: "RWTV" } }
      sort: { fields: order }
    ) {
      edges {
        node {
          id
          link
          linkTo
          title
          description
          long_description
          video {
            asset {
              url
            }
          }
        }
      }
    }
    allSanityStoreSettings {
      edges {
        node {
          front_video {
            asset {
              url
            }
          }
          contact_text
        }
      }
    }
  }
`;
