import React from 'react';
import styled from 'styled-components';

export const ContactText = ({ text, columnId, active }) => (
  <StyledContainer columnId={columnId} active={active}>
    <p> {text} </p>
  </StyledContainer>
);

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: -50px 25px;
  @media screen and (max-width: 800px) {
    margin: -50px 0;
    justify-content: center;
  }

  p {
    z-index: 9999;
    font-size: 10px;
    line-height: 25px;
    letter-spacing: 0.01px;
    color: ${(p) => (p.active || p.columnId === 1 ? '#fff' : '#000')};
    font-weight: normal;
    @media screen and (max-width: 800px) {
      color: ${(p) => (p.active || p.columnId === 0 ? '#fff' : '#000')};
    }
  }
`;
